import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { BfcAuthenticationService } from "@bfl/components/authentication";

@Injectable()
export class IdentifyAuthGuard implements CanActivate {

  code: string;

  constructor(
    private bfcAuthenticationService: BfcAuthenticationService,
    private router: Router,
  ) {}

  canActivate(): boolean {
    const isAuthenticated: boolean = this.bfcAuthenticationService.authenticated;

    if (isAuthenticated) {
      this.router.navigate(["appointments"]);
    }
    return !isAuthenticated;
  }
}